// Font Family
$font-family: "Poppins", sans-serif;

// Theme Colors
$theme-color1: #007AFF;
$theme-color2: #010101;
$text-color: #555555;
$grey-color: #e2dada;
$white: #ffffff;
$black: #000000;
$placehoder-color: #b7b7b7;

//  Heading Tags Font size larger screen
$h1-font-size: 56px;
$h2-font-size: 40px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;

// Border Radius
$border-radius-xxl: 32px;
$border-radius-lg: 24px;
$border-radius-md: 16px;
$border-radius-sm: 8px;

// Section Pading Larger Screen
$section-pading: 100px;


//  Heading Tags Font size Smaller screen
$h1-font-size-resp: 40px;
$h2-font-size-resp: 32px;
$h3-font-size-resp: 24px;
$h4-font-size-resp: 20px;
$h5-font-size-resp: 18px;
$h6-font-size-resp: 16px;

// Section Pading Smaller Screen
$section-pading-resp: 75px;


