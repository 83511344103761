.privacy-policy {
  padding: 20px;
  line-height: 1.6;
}

.privacy-policy h1 {
  color: #333;
  font-size: 24px;
}

.privacy-policy h2 {
  color: #333;
  font-size: 22px;
}

.privacy-policy p, .privacy-policy ul {
  margin-bottom: 20px;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy address {
  margin-top: 20px;
}

.privacy-policy a {
  color: #007bff;
}
