.center-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.form-container {
  width: 352px;
  min-height: 360px; /* Adjust the width of the form container */
  /* Add other styles as needed */
}

.login-button {
  background-color: $theme-color1; /* Sky blue color */
  color: white; /* White text */
  padding: 6px; /* Padding for size */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Text size */
  font-weight: 600; /* Bold text */
  text-align: center; /* Center the text */
  cursor: pointer; /* Pointer cursor on hover */
  display: block; /* Make it block element */
  margin: 20px auto;
  width: 70%; /* Center horizontally with margin */
}

.login-button:hover {
  background-color: #0066CC; /* Darker blue on hover */
}

.login-button:disabled {
  background-color: #99CCFF;
  cursor: not-allowed;
}

.error {
  color: red; /* Text color for error message */
  font-size: 14px; /* Font size for error message */
  margin-top: 5px; /* Spacing above the error message */
   /* Bold text */
}

.link {
  margin-right: 10px; /* Adjust the margin as needed */
  font-size: 14px;
}

/* ==========================================================================================
   -- Card --
========================================================================================== */

/* Card */
.card {
  --border-color: rgba(0, 0, 0, 0.1); /* Replace with appropriate color */
  --card-bg: #fff; /* Replace with appropriate background color */
  --card-header-bg: #f8f9fa; /* Replace with appropriate header background color */
  --card-footer-bg: #f8f9fa; /* Replace with appropriate footer background color */
  max-width: 30.5rem;
  margin-bottom: 1rem; /* Replace with appropriate margin */
  border: 1px solid var(--border-color);
  background-color: var(--card-bg);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); /* Replace with appropriate shadow */

  /* Card in card */
  .card-body > & {
      &:last-child {
          margin-bottom: 0;
      }
  }

  /* Status */
  /* Stretch */
  &-stretch {
      &-full {
          height: calc(100% - 1rem); /* Replace with appropriate grid gutter width */

          &.mb-1,
          &.my-1 {
              height: calc(100% - 0.25rem); /* Replace with appropriate spacer value */
          }
      }

      &-semi {
          height: calc(50% - 1rem); /* Replace with appropriate grid gutter width */
      }
  }

  /* Compact */
  &-compact {
      > *:not(:first-child) {
          padding-top: 0;
      }

      > *:not(:last-child) {
          padding-bottom: 0;
      }

      &-header {
          margin-bottom: 0.5rem; /* Replace with appropriate card title spacer */

          min-height: 3.5rem; /* Replace with appropriate header min height */
      }
  }
}

/* Components */
.card-header {/* Replace with appropriate padding */
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  background-color: $theme-color1;
  color: #aa0faa; /* Replace with appropriate header color */
}

.card-label {
  display: flex;
  align-items: center;
}

.card-icon {
  margin-right: 0.75rem; /* Replace with appropriate icon space */
  color: #212529; /* Replace with appropriate icon color */
  font-size: 1.25rem; /* Replace with appropriate icon size */
}

.card-title {
  font-weight: 500; /* Replace with appropriate title font weight */

  .card-label & {
      margin-bottom: 0;
  }

  small {
      font-size: 61%;
      opacity: 0.5;
  }
}

.card-subtitle {
  .card-label .card-title + & {
      margin-top: 0.25rem;
  }
}

.card-actions {
  display: flex;
  justify-content: flex-end;
}

.card-body {
  padding: 1rem 1.5rem; /* Replace with appropriate padding */

  > *:last-child {
      margin-bottom: 0;
  }

  /* Scrollable */
  &-scrollable {
      @media (min-width: 576px) {
          overflow: auto;
          height: 10rem;
      }
  }
}

.card-footer {
  padding: 0.75rem 1rem; /* Replace with appropriate padding */

  display: flex;
  min-height: 3.5rem; /* Replace with appropriate footer min height */
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  background-color: var(--card-footer-bg);
  color: #212529; /* Replace with appropriate footer color */

  @media (max-width: 767.98px) {
      flex-direction: column;
      align-items: baseline;

      > *:not(:last-child) {
          margin-bottom: 0.75rem; /* Replace with appropriate footer padding */
      }
  }

  &:last-child {
      border-radius: 0 0 0.25rem 0.25rem; /* Replace with appropriate border radius */
  }

  /* Status */
  /* Height */
  &.card-footer-sm {
      padding: 0.5625rem 1rem; /* 75% of original padding */

      min-height: 2.625rem; /* 75% of original min height */
  }

  &.card-footer-lg {
      padding: 0.9375rem 1rem; /* 125% of original padding */

      min-height: 4.375rem; /* 125% of original min height */
  }

  /* Border Size */
  &-border-thin {
      border-top-width: 1px; /* Replace with appropriate border width */
  }

  &-border-thick {
      border-top-width: 2px; /* Replace with appropriate border width */
  }

  /* Border Color */
  &-border-primary {
      border-top-color: rgba(0, 123, 255, 0.5); /* Replace with appropriate theme color */
  }

  &-border-secondary {
      border-top-color: rgba(108, 117, 125, 0.5); /* Replace with appropriate theme color */
  }
}

.card-footer-left,
.card-footer-right {
  display: flex;
}

.card-footer-right {
  margin-left: auto;
}


/* ==========================================================================================
   -- Buttons --
========================================================================================== */
.button {
  background: $theme-color1;
  border: none;
  color: $white;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    background: $theme-color2;
    color: $white;
  }
}

.anchor-button {
  display: inline-flex;
  border-radius: 50px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;

  &.primary {
    background: $theme-color1;
    color: $white;
  }

  &.secondary {
    background: $white;
    color: $theme-color1;
  }

  &:hover {
    background: $theme-color2;
    color: $white;
  }
}

.button-lg {
  padding: 16px 32px;
}
.button-sm {
  padding: 8px 16px;
}

/* ==========================================================================================
   -- Sections --
========================================================================================== */
.sp-t {
  padding-top: $section-pading;
}

.sp-tb {
  padding-top: $section-pading;
  padding-bottom: $section-pading;
}

.sp-b {
  padding-bottom: $section-pading;
}

.section-heading {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    br {
      @include max992 {
        display: none;
      }
    }
  }

  &.white {
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
      br {
        @include max992 {
          display: none;
        }
      }
    }

    .sub-heading {
      color: $white;
    }
  }
}

.sub-heading {
  color: $theme-color1;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
}

/* ==========================================================================================
   -- Data Display Boxes --
========================================================================================== */

.iconbox-primary {
  .item-icon {
    .las,
    .lar,
    .lab {
      font-size: 64px;
      color: $theme-color1;
    }
  }

  h3,
  h4 {
    margin-bottom: 16px;
  }
}

.iconbox-secondary {
  display: flex;

  .item-icon {
    .las,
    .lar,
    .lab {
      font-size: 48px;
      color: $theme-color1;
    }
  }

  h3,
  h4 {
    margin-bottom: 8px;
  }
}

.iconbox-list {
  .item-icon {
    .las,
    .lar,
    .lab {
      font-size: 24px;
      color: $theme-color1;
    }
  }
}

/* ==========================================================================================
   -- Forms --
========================================================================================== */
input[type="text"] {
  width: 100%;
  padding: 16px 24px;
  border: 1px solid $grey-color;
  border-radius: 50px;
}

textarea {
  width: 100%;
  padding: 16px 24px;
  border: 1px solid $grey-color;
  border-radius: 32px;
}

/* ==========================================================================================
   -- Preloader --
========================================================================================== */
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $theme-color1;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      border: 4px solid #fff;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* ==========================================================================================
     -- Back To Top --
  ========================================================================================== */
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  text-align: center;
  background: $theme-color1;
  color: $white;
  border-radius: $border-radius-xxl;
  padding: 8px;
  transition: all 0.3s;
  cursor: pointer;
  display: none;
  &:hover {
    background: $theme-color2;
  }

  .icon-top {
    .las {
      font-size: 32px;
    }
  }
  .top-text {
    font-size: 12px;
  }
}
