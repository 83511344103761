* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  color: $text-color;
}

img {
  max-width: 100%;
}

:active,
:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  margin-bottom: 16px;
  font-weight: 600;
}

table p {
  margin-bottom: 0;
}

p {
  margin: 0;
}
a {
  color: $black;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

a:hover {
  text-decoration: underline;
  color: $theme-color2;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a img {
  border: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 10px;
}

ol,
ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

iframe {
  border: none !important;
}

.site-bg {
  background: $theme-color1;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  @include min1200 {
    max-width: 1200px;
  }
}
