/* ==========================================================================================
   -- container --
========================================================================================== */

.home-container {
    width: 100%;
    justify-content: center;
    align-items: top;
  }
  
  .home-card {
    margin: 0 auto;
   max-width: 800px;
    --border-color: rgba(0, 0, 0, 0.1); /* Replace with appropriate color */
    --card-bg: #fff; /* Replace with appropriate background color */
    --card-header-bg: #f8f9fa; /* Replace with appropriate header background color */
    --card-footer-bg: #f8f9fa; /* Replace with appropriate footer background color */
    margin-bottom: 1rem; /* Replace with appropriate margin */
    border: 1px solid var(--border-color);
    background-color: var(--card-bg);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); /* Replace with appropriate shadow */
    padding: 8px;
    float: top;
    display: flex;
  }

  .home-item-image {
    width: 100px;
    min-width: 100px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .home-item-title {
    vertical-align: top; /* Aligns the text to the top */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
  font-weight: 600;
  font-size: 16px;
  max-lines: 3;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  }