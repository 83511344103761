.calendar-page-content {
    padding: 50px;
    margin-bottom: 10px;
    margin-top: 60px;
}

@media (max-width: 768px) {
    .calendar-page-content {
        padding:16px;
    }
}


.calendar-title {
    vertical-align: top;
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 20px;
}

.calendar-chevron {
    color: #007AFF;
    height: 24px;
}

.calendar-today-button {
    background: #007AFF;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    padding: 2px 16px;
    border: none;
}

.calendar-card {
    margin: 0 auto;
    // --border-color: rgba(0, 0, 0, 0.1);
    /* Replace with appropriate color */
    --card-bg: #fff;
    /* Replace with appropriate background color */
    --card-header-bg: #f8f9fa;
    /* Replace with appropriate header background color */
    --card-footer-bg: #f8f9fa;
    /* Replace with appropriate footer background color */
    margin-bottom: 1rem;
    /* Replace with appropriate margin */
    // border: 1px solid var(--border-color);
    background-color: var(--card-bg);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    /* Replace with appropriate shadow */
    float: top;
    display: flex;
}

.calendar-highlighted-card {
    margin: 0 auto;
    // --border-color: rgba(0, 0, 0, 0.1);
    /* Replace with appropriate color */
    --card-bg: #fff;
    /* Replace with appropriate background color */
    --card-header-bg: #f8f9fa;
    /* Replace with appropriate header background color */
    --card-footer-bg: #f8f9fa;
    /* Replace with appropriate footer background color */
    margin-bottom: 1rem;
    /* Replace with appropriate margin */
    // border: 1px solid var(--border-color);
    background-color: var(--card-bg);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    /* Replace with appropriate shadow */
    float: top;
    display: flex;
    padding: 16px;
    border-radius: 16px;
    max-width: 320px;

}

.event-item {
    white-space: 'normal';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
    word-wrap: 'break-word';
    padding: 10px;
    border-radius: 16px;
}

.event-title {
    vertical-align: top;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 12px;
    max-lines: 2;
    -webkit-line-clamp: 2;
    // color: ;

}

.rbc-event {
    flex: 0 0 auto;
    width: auto;
  }

.event-time {
    display: 'inline-block';
    text-overflow: 'ellipsis';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight:700;
    font-size: 12px;
    max-lines: 1;
}

.rbc-time-gutter {
    display: none;
}

.rbc-timeslot-group {
    display: none;
}

.rbc-time-gutter {
    display: none;
}

.rbc-event-label {
    display: none;
}

.rbc-label {
    display: none;
}

.counter-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .counter-label {
    margin-right: 10px;
    font-size: 14px;
    width: 50px;
  }
  
  .counter {
    display: flex;
    align-items: center;
  }
  
  .counter-button {
    background-color: #007bff;
    width: 30px;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0px 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .counter-button:hover {
    background-color: #0056b3;
  }
  
  .counter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
  }
  .counter-value {
    font-size: 16px;
    font-weight: semi-bold;
    text-align: center;
  }

  .form-input {
    font-size: 14px;
    font-weight: 300;
    padding: 2px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
  }

  .book-button {
    background-color: $theme-color1;
    color: white; 
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    display: block;
    padding: 8px 16px;
    margin: 0% auto;
    width: 80%;
  }

  .cancel-button {
    background-color: rgb(185, 20, 20);
    color: white; 
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    display: block;
    padding: 4px 16px;
    margin: 0% auto;
    width: 80%;
  }

  .booking-status-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .booking-status-CONFIRMED-circle {
    width: 16px;
    height: 16px;
    background-color: rgb(42, 220, 42);
    box-shadow: 0 0 4px rgba(0, 255, 0, 0.6), 0 0 4px rgba(0, 255, 0, 0.4), 0 0 4px rgba(0, 255, 0, 0.2);
    border-radius: 50%;
}

.booking-status-CANCELLED-circle {
  width: 16px;
  height: 16px;
  background-color: rgb(165, 27, 27);
  box-shadow: 0 0 4px rgba(96, 6, 6, 0.6), 0 0 4px rgba(0, 255, 0, 0.4), 0 0 4px rgba(0, 255, 0, 0.2);
  border-radius: 50%;
}

.booking-label {
    font-size: 146x;
}

.booking-button-tertiary {
    background-color: transparent;
    color: rgb(225, 61, 61);
    border: none;
    text-decoration: underline;
}

.booking-button-tertiary:hover {
    color:rgb(195, 31, 31);
}

.booking-close-button-tertiary {
  background-color: transparent;
  color: $theme-color1;
  border: none;
  text-decoration: underline;
}