@mixin min1200 {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin max1200 {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin max992 {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin max767 {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin max425 {
  @media only screen and (max-width: 425px) {
    @content;
  }
}
